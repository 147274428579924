import cl from "./header.module.css";
import { GenTypeToggleBtn } from "../genTypeToggleBtn/genTypeToggleBtn";
import { memo } from "react";
export const Header = memo(({ setIsOpen, setIsAudio }) => {
	return (
		<div className={cl.gen_header}>
			<GenTypeToggleBtn setIsAudio={setIsAudio} />
			<button onClick={setIsOpen} className={cl.closeBtn}>
				<img
					className={cl.closeIcon}
					src="/generateVideoWindow/closeIcon.svg"
					alt="Иконка закрытия"
				/>
			</button>
		</div>
	);
});
