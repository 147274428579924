/**
 * Функция переводит байты в килобайты/мегабайты/гигабайты
 * @param {number} bytes - число байтов
 * @param {number} decimals - число знаков после запятой (по дефолту 2)
 * @returns {string} - размер в кб/мб/гб
 */
export function bytesToSize(bytes, decimals = 2) {
	if (!Number(bytes)) {
		return "0 Bytes";
	}

	const kbToBytes = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

	const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));

	return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${
		sizes[index]
	}`;
}
