import React from "react";
import SaveButton from "./SaveButton/SaveButton";
import cl from "./TopBtnsRow.module.css";
import UploadButton from "./UploadButton/UploadButton";

const TopBtnsRow = () => {
	return (
		<div
			className={cl.flex}
			style={{ padding: "0 0 10px 0", justifyContent: "flex-start" }}
		>
			<div className={cl.flex} style={{ gap: "15px" }}>
				<UploadButton />
				<SaveButton />
			</div>
		</div>
	);
};

export default TopBtnsRow;
