import cl from "./messageFile.module.css";
import { bytesToSize } from "../../../shared/utils/byteToSizeConverter";
import { useFilePreview } from "../hooks/useFilePreview";
import { fileLoaderContext } from "../main/main";
import { useContext } from "react";

export const MessageFile = ({ file, isUserMessage }) => {
	const { filePreviewUrl } = useFilePreview(file);
	const uploadFileToEditor = useContext(fileLoaderContext);

	const fileNameValidation = (fileName) => {
		return `${fileName.substring(0, 22)}${fileName.length > 25 ? "..." : ""}`;
	};
	return (
		<div
			style={{
				backgroundColor: isUserMessage ? "white" : "rgba(221, 233, 255, 0.5)",
			}}
			className={cl.file}
		>
			<img
				className={cl.file_preview}
				src={filePreviewUrl}
				alt="Превью загруженного файла"
			/>
			<div className={cl.file_info}>
				<p title={file.name} className={cl.file_name}>
					{fileNameValidation(file.name)}
				</p>
				<p title={`${file.size} байт`} className={cl.file_size}>
					{bytesToSize(file.size)}
				</p>
			</div>
			<div className={cl.file_btns}>
				<a
					title="Скачать"
					className={cl.downloadBtn}
					href={URL.createObjectURL(file)}
					download
				>
					<img
						className={cl.downloadIcon}
						src="./chatBotWindow/downloadIcon.svg"
						alt="Иконка загрузки"
					/>
				</a>
				<button
					onClick={() => uploadFileToEditor(file)}
					title="Переместить в редактор"
					className={cl.editBtn}
				>
					<img
						className={cl.editIcon}
						src="./chatBotWindow/editIcon.svg"
						alt="Переместить в редактор"
					/>
				</button>
			</div>
		</div>
	);
};
