import { useContext, useState } from "react";
import cl from "./media.module.css";
import { generateTypeContext } from "../generateWindow/generateWindow";
import Player from "griffith";

export const Media = ({ transferUrlToEditor, mediaUrl = "" }) => {
	const isAudio = useContext(generateTypeContext);
	const [isButtonsVisible, setIsButtonsVisible] = useState(false);

	const sources = {
		hd: {
			play_url: mediaUrl,
		},
	};

	const handleMouseEnter = () => {
		setIsButtonsVisible(true);
	};

	const handleMouseLeave = () => {
		setIsButtonsVisible(false);
	};
	return (
		<div className={cl.media}>
			{mediaUrl ? (
				<div
					className={cl.video}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<>
						<a
							title="Скачать"
							className={`${cl.downloadBtn} ${isButtonsVisible ? cl.visible : cl.hidden}`}
							href={mediaUrl}
							download
						>
							<img
								className={cl.downloadIcon}
								src="./generateVideoWindow/downloadIcon.svg"
								alt="Иконка загрузки"
							/>
						</a>
						<button
							onClick={() => transferUrlToEditor(mediaUrl)}
							title="Переместить в редактор"
							className={`${cl.editBtn} ${isButtonsVisible ? "visible" : "hidden"}`}
						>
							<img
								className={cl.editIcon}
								src="./generateVideoWindow/editIcon.svg"
								alt="Переместить в редактор"
							/>
						</button>
					</>
					<Player sources={sources} />
				</div>
			) : (
				<div className={cl.videoSkeleton}>
					<img
						className={cl.previewIcon}
						src={
							isAudio
								? "/generateVideoWindow/audioPreview.svg"
								: "/generateVideoWindow/videoPreview.svg"
						}
						alt="Иконка превью видео"
					/>
					<p className={cl.skeletonTxt}>Сгенерируйте {isAudio ? "аудио" : "видео"}</p>
				</div>
			)}
		</div>
	);
};
