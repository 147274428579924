import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import api from "../../../../api/apiNeurals";
import store from "../../../../store/neuralWindow.jsx";
import ResultWindowState from "../../../ResultWindow/ResultWindowState.jsx";
import ImagesBlock from "../../imagesBlock/imagesBlock.jsx";
import Caption from "../Caption/Caption.jsx";
import Manual from "../Manual/Manual.jsx";
import cl from "./Parametrs.module.css";
import { base64ToBlob } from "./base64Converter.jsx";
import { ParamSwitch } from "./paramSwitch.jsx";

function setSendImgArray(ImgFilesBase64) {
	let binaryToSend = [];
	ImgFilesBase64.forEach((file) => {
		binaryToSend.push(base64ToBlob(file));
	});

	return binaryToSend;
}

const Parametrs = observer(({ closeWindow, closeParam }) => {
	const [img, setImg] = useState([]); // массив картинок для отправки на сервер
	const [attention, setAttention] = useState(""); // предупреждение о невалидности параметров для отправки на сервер
	const [searchValue, setSearchValue] = useState("");
	const [isCaption, setIsCaption] = useState(false);

	const paramsToRender = store.parametrs;
	let renderValue = store.defaultValue;
	const neuralName = store.activeNeuralName;

	useEffect(() => {
		// store.captions - фактическое описание( массив строк)
		if (!store.captions.some((caption) => caption === "") && isCaption) setAttention("");
	}, [store.captions]);

	useEffect(() => {
		if (img.length) setAttention("");
	}, [img]);

	useEffect(() => {
		const setCaptionFlag = () => {
			if (
				store.childParams.includes("isCaption") ||
				store.activeNeuralName === "stylization" || // нейронки без поля с выбором модели
				store.activeNeuralName === "image_captioning"
			)
				setIsCaption(true);
			else setIsCaption(false);
		};

		setCaptionFlag();
	}, [store.currentModel, store.activeNeuralName]);

	const sendValuesForRender = (value, str) => {
		renderValue = { ...renderValue, [str]: value };
	};

	const startGeneration = () => {
		// проверка наличия описания
		if (isCaption && store.captions.some((caption) => caption === "")) {
			setAttention("*Введите описание для модели");
			return;
		}

		//проверка наличия изображения
		if (
			(!img.length || img.length !== store.maxImageAmount) &&
			neuralName !== "text_to_image"
		) {
			if (img.length < store.maxImageAmount)
				setAttention("*Добавьте изображение для генерации");
			else setAttention("*Удалите изображение");

			return;
		}

		goOnServer();
		store.startGeneration();
	};

	const goOnServer = async () => {
		const formData = new FormData();
		let binary = setSendImgArray(img);
		formData.append("NeuralType", neuralName);
		formData.append("Parameters", JSON.stringify(renderValue));
		formData.append("Caption", store.captions);
		formData.append("Prompts", ["", ""]); //надо узнать че это такое
		binary.forEach((file) => {
			formData.append(`ImagesInput`, file);
		});

		try {
			const response = await api.RunNeural(formData);
			const images = response.data.images;
			store.endGeneration();

			if (images) {
				store.endGeneration();
				ResultWindowState.setImages(images);
				ResultWindowState.setIsOpen(true);
			} else {
				console.log(response);
				alert("При генерации возникла ошибка");
			}
		} catch (e) {
			alert("Произошла ошибка сервера");
			store.endGeneration();
			console.error(e);
			throw e;
		}
	};

	const getSearchValue = (e) => {
		setSearchValue(e.target.value);
	};

	const getSearchResult = () => {
		return paramsToRender.filter((param) => {
			const paramSystemName = Object.keys(param);
			return param[paramSystemName[0]].name
				.toLowerCase()
				.includes(searchValue.toLowerCase());
		});
	};
	return (
		<div>
			<ImagesBlock closeWindow={closeWindow} setSendImages={setImg} sendImages={img} />
			{neuralName ? (
				<>
					<div className={cl.params}>
						<div className={cl.paramsHeader}>
							<input
								onChange={getSearchValue}
								type="text"
								placeholder="Найти параметры..."
								className={cl.findParam}
							/>
							<button className={cl.saveParam}>
								<span className={`${cl.txt} ${cl.txt__saveParam}`}>
									Сохранить параметры
								</span>
							</button>
						</div>
						<div className={cl.paramCont}>
							<div className={cl.paramsList}>
								{isCaption && <Caption />}
								{searchValue.length > 1
									? getSearchResult().map((param, id) => (
											<ParamSwitch
												key={id}
												value={param}
												id={id}
												setValueForServer={sendValuesForRender}
											/>
									  ))
									: paramsToRender.map((param, id) => (
											<ParamSwitch
												key={id}
												value={param}
												id={id}
												setValueForServer={sendValuesForRender}
											/>
									  ))}
							</div>
						</div>
					</div>
					<div className={cl.downBtns}>
						<button onClick={() => store.endGeneration()} className={cl.cancel}>
							<span className={cl.cancel__txt}>Отмена</span>
						</button>
						{store.isGenerationEnd ? (
							<div className={cl.attention}>
								<button className={cl.downBtns__generate} onClick={startGeneration}>
									<span className={cl.txt}>Сгенерировать</span>
								</button>
								{attention && <span className={cl.attentionTxt}>{attention}</span>}
							</div>
						) : (
							<div className={`${cl.downBtns__generate} ${cl.loadingMode}`}>
								<span className={cl.txt}> Идёт генерация...</span>
								<div className={cl.loading}>
									<div className={cl.spin}></div>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<Manual />
			)}
		</div>
	);
});

export default Parametrs;
