import React, { useState } from "react";
import canvasState from "../../../store/canvasState.tsx";
import toolState from "../../../store/toolState.tsx";
import ClearPane from "../canvasTools/ClearPane/ClearPane";
import ColorPalete from "../canvasTools/ColorPalete/ColorPalete";
import Eraser from "../canvasTools/Eraser/Eraser";
import Pencil from "../canvasTools/Pencil/Pencil";
import Bezie from "../toolsMobx/Bezie";
import Filling from "../toolsMobx/test";
import cl from "./ToolBar.module.css";

const ToolBar = () => {
	const [activeBtn, setActiveBtn] = useState(null);
	const [color, setColor] = useState("#000000");

	const ResColor = (e) => {
		setColor(e.target.value);
		toolState.setStrokeColor(e.target.value);
		toolState.setFillColor(e.target.value);
	};
	const Fill = (e) => {
		e.preventDefault();
		toolState.setTool(new Filling(canvasState.canvas));
	};

	const Bezies = (e) => {
		e.preventDefault();
		toolState.setTool(new Bezie(canvasState.canvas));
	};

	return (
		<div className={cl.nav}>
			<Pencil activeBtn={setActiveBtn} active={activeBtn} />

			<a className={cl.el} title="Перо" onClick={(e) => Bezies(e)}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="43"
					height="43"
					viewBox="0 0 43 43"
					fill="none"
				>
					<g clip-path="url(#clip0_42_456)">
						<path
							d="M11.3143 26.2801C7.55795 26.2801 4.52572 29.4808 4.52572 33.4458C4.52572 36.5748 1.9008 38.2229 0 38.2229C2.08183 41.1369 5.63452 43 9.05144 43C14.0524 43 18.1029 38.7245 18.1029 33.4458C18.1029 29.4808 15.0706 26.2801 11.3143 26.2801ZM42.3381 3.89932L39.3059 0.698653C38.4234 -0.232884 36.9978 -0.232884 36.1152 0.698653L15.84 22.1001L22.0629 28.6687L42.3381 7.26718C43.2206 6.33565 43.2206 4.83086 42.3381 3.89932Z"
							fill="#656565"
						/>
					</g>
					<defs>
						<clipPath id="clip0_42_456">
							<rect width="43" height="43" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</a>

			<Eraser activeBtn={setActiveBtn} active={activeBtn} />

			<a onClick={(e) => Fill(e)} className={cl.el}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="43"
					height="43"
					viewBox="0 0 43 43"
					fill="none"
				>
					<g clip-path="url(#clip0_42_446)">
						<path
							d="M32.3948 22.5411L12.7131 3.66988C11.7816 2.77671 10.2768 2.77671 9.34523 3.66988C8.41369 4.56306 8.41369 6.00588 9.34523 6.89905L13.358 10.7466L1.05694 22.5411C-0.352312 23.8923 -0.352312 26.068 1.05694 27.3963L14.194 39.9923C14.8867 40.6565 15.8182 41 16.7259 41C17.6335 41 18.5651 40.6565 19.2577 39.9923L32.3948 27.3963C33.8041 26.068 33.8041 23.8923 32.3948 22.5411ZM5.28468 24.9687L16.7259 13.9986L28.1671 24.9687H5.28468ZM38.2229 28.4039C38.2229 28.4039 33.4458 33.3737 33.4458 36.4196C33.4458 38.9388 35.5955 41 38.2229 41C40.8503 41 43 38.9388 43 36.4196C43 33.3737 38.2229 28.4039 38.2229 28.4039Z"
							fill="#656565"
						/>
					</g>
					<defs>
						<clipPath id="clip0_42_446">
							<rect width="43" height="43" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</a>
			<label className={cl.el}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M16 15C15.4477 15 15 15.4477 15 16V47C15 47.5523 15.4477 48 16 48H47C47.5523 48 48 47.5523 48 47V16C48 15.4477 47.5523 15 47 15H16ZM43.2856 19.7143H19.7142V43.2857H43.2856V19.7143Z"
						fill="#656565"
					/>
					<path
						d="M47 16L16 47"
						stroke="#CE3E3E"
						stroke-width="2"
						stroke-linecap="round"
					/>
					<rect width="33" height="33" rx="1" fill={color} />
				</svg>
				<input className={cl.input__hidden} type="color" onChange={(e) => ResColor(e)} />
			</label>

			<ClearPane />

			<div className="old__version" style={{ display: "none" }}>
				<Pencil activeBtn={setActiveBtn} active={activeBtn} />
				<Eraser activeBtn={setActiveBtn} active={activeBtn} />
				<ColorPalete activeBtn={setActiveBtn} active={activeBtn} />
				<ClearPane />
			</div>
		</div>
	);
};

export default ToolBar;
