import React, { useState } from "react";
import toolState from "../../../../store/toolState.tsx";
import cl from "./MyInput.module.css";
const MyInput = (props) => {
	const [value, setValue] = useState(1);

	const [range, setRange] = useState(toolState.width);
	const Grad = (e) => {
		toolState.setLineWidth(e.target.value);
		setValue(e.target.value);
		e.preventDefault();
		setRange(toolState.width);
	};

	return (
		<label
			className={cl.opacity__block}
			style={{
				background: `linear-gradient(to right, #93BBE3 ${toolState.width}%, #ABABAB 0%)`,
			}}
		>
			<span className={cl.opacity__text}>Толщина: {toolState.width}px</span>
			<input
				type="range"
				style={{ opacity: 0 }}
				value={1}
				min="1"
				max="100"
				onChange={(e) => Grad(e)}
			/>
		</label>
	);
};
export default MyInput;
