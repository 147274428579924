import GenerateWindow from "../src/components/GenerateWindow/GenerateBtn/GenerateBtn.jsx";
import { MainBabylon } from "./components/3dModule/mainBabylon";
import AboutUs from "./components/AboutUs/AboutUs.jsx";
import Auth from "./components/Auth/Auth.tsx";
import Drawing from "./components/Drawing";
import { DrawingtoImg } from "./components/DrawingtoImg";
import { Pay } from "./components/Payment/Payment.jsx";
import Regisration from "./components/Registration/Registration.tsx";
import ResultWindow from "./components/ResultWindow/ResultWindow";
import { UserProfile } from "./components/UserProfile/UserProfile.jsx";
import ApiAuthorzationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { StartPage } from "./components/startPage/startPage";
import VideoEditor from "./components/videoEditor/Video_Editor/VideoEditor.js";
const AppRoutes = [
	{
		index: true,
		element: <StartPage />,
	},
	{
		path: "/adaptive-window",
		requireAuth: true,
		element: <GenerateWindow />,
	},
	{
		path: "/drawing-to-img",
		requireAuth: true,
		element: <Drawing />,
	},
	{
		path: "/AboutUs",
		requireAuth: false,
		element: <AboutUs />,
	},
	{
		path: "/result",
		requireAuth: true,
		element: <ResultWindow />,
	},
	{
		path: "/videoEditor",
		requireAuth: true,
		element: <VideoEditor />,
	},
	{
		path: "/registration",
		requireAuth: true,
		element: <Regisration />,
	},
	{
		path: "/auth",
		requireAuth: true,
		element: <Auth />,
	},
	{
		path: "/test",
		requireAuth: true,
		element: <DrawingtoImg />,
	},
	{
		path: "/Pay",
		requireAuth: false,
		element: <Pay />,
	},
	{
		path: "/3d",
		requireAuth: true,
		element: <MainBabylon />,
	},
	{
		path: "/myProfile",
		requireAuth: true,
		element: <UserProfile />,
	},
	{
		path: "/api",
		requireAuth: true,
		element: Proxy,
	},
	...ApiAuthorzationRoutes,
];

export default AppRoutes;
