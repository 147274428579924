import React from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import cl from "./ToolOptionsBar.module.css";

const Arrows = () => {
	return (
		<div className={cl.navCanvas}>
			<div className={cl.flex}>
				<LeftArrow />
				<RightArrow />
			</div>
		</div>
	);
};

export default Arrows;
