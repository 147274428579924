import cl from "./main.module.css";
import { OpenBtn } from "../openBtn/openBtn";
import { ChatWindow } from "../chatWindow/chatWindow";
import { createPortal } from "react-dom";
import { createContext, useState } from "react";

export const fileLoaderContext = createContext(null);
export const Main = ({ uploadFileToEditor }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			{createPortal(
				<div className={cl.chat_main}>
					{isOpen && (
						<fileLoaderContext.Provider value={uploadFileToEditor}>
							<ChatWindow setIsOpen={setIsOpen} />
						</fileLoaderContext.Provider>
					)}
					<OpenBtn setIsOpen={setIsOpen} />
				</div>,
				document.getElementById("root")
			)}
		</>
	);
};
