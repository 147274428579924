import axios from "axios";
import AuthorizeService from "../components/api-authorization/AuthorizeService";
import ApiToken from "./ApiToken";

export default class MediaGeneration {
	/**
	 * Получение ответа при генерации видео/аудио
	 * @async
	 * @static
	 * @param {FormData} formData - Объект FormData для отправки.
	 * FormData = {
	 *  type: "audio" | "video",
	 *  prompt: string,
	 *  isLongVideo: boolean // это поле есть, только если генерируем видео
	 * }
	 * @returns {Promise<Object|number>} Возвращает promise с объектом с полями file:blob | null
	 * или кодом 401
	 */

	static async GetMedia(formData) {
		if (!(await AuthorizeService.isAuthenticated()))
			return axios.HttpStatusCode.Unauthorized;

		return await axios.post(
			"api/neural/mediaGeneration",
			formData,
			await ApiToken.GetConfigToken()
		);
	}

	/**
	 * Обрезка видео/аудио
	 * @async
	 * @static
	 * @param {FormData} formData - Объект FormData для отправки.
	 * FormData = {
	 *  media: blob,
	 *  metadata: { trim_times: [{start: number, end: number}] }
	 * }
	 * @returns {Promise<Object|number>} Возвращает promise с объектом с полями file:blob | null
	 * или кодом 401
	 */
	static async CutMedia(formData) {
		console.log(formData)
		if (!(await AuthorizeService.isAuthenticated()))
			return axios.HttpStatusCode.Unauthorized;

		return await axios.post(
			"api/neural/cutMedia",
			formData,
			await ApiToken.GetConfigToken()
		);
	}
}
