import menuLight from './stylesLight/headerMenu.module.css'
import menu from './stylesDark/headerMenu.module.css'
import { createPortal } from 'react-dom';

export const HeaderMenu = ({ setIsVisible, isLightTheme }) => {
	return (
		createPortal(
			<div className={isLightTheme ? menuLight.main : menu.main}>
				<button onClick={() => setIsVisible(false)} className={isLightTheme ? menuLight.closeBtn : menu.closeBtn}>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<g clipPath="url(#clip0_422_365)">
							<path d="M22 2L2 22" stroke={isLightTheme ? "#656565" : '#FFFF'} strokeWidth="4" strokeLinecap="round" />
							<path d="M22 22L2 2" stroke={isLightTheme ? "#656565" : '#FFFF'} strokeWidth="4" strokeLinecap="round" />
						</g>
						<defs>
							<clipPath id="clip0_422_365">
								<rect width="24" height="24" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</button>
				<div className={isLightTheme ? menuLight.account : menu.account}>
					<span className={isLightTheme ? menuLight.txt : menu.txt}>Аккаунт</span>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
						<path d="M15.7134 12.7093C16.6986 11.9384 17.4178 10.8813 17.7708 9.68501C18.1237 8.48872 18.093 7.21276 17.6829 6.03464C17.2727 4.85652 16.5036 3.83483 15.4823 3.11171C14.4611 2.38859 13.2387 2 11.9851 2C10.7315 2 9.50904 2.38859 8.48784 3.11171C7.46664 3.83483 6.69746 4.85652 6.28731 6.03464C5.87716 7.21276 5.84644 8.48872 6.19943 9.68501C6.55241 10.8813 7.27154 11.9384 8.25678 12.7093C6.56856 13.382 5.09553 14.4976 3.99473 15.9374C2.89392 17.3771 2.20661 19.0869 2.00606 20.8846C1.99154 21.0159 2.00316 21.1487 2.04026 21.2754C2.07735 21.4022 2.13918 21.5205 2.22224 21.6235C2.38997 21.8316 2.63393 21.9648 2.90046 21.994C3.16698 22.0231 3.43424 21.9458 3.64343 21.779C3.85263 21.6122 3.98662 21.3695 4.01594 21.1045C4.23661 19.1508 5.17331 17.3465 6.64708 16.0362C8.12086 14.7259 10.0284 14.0016 12.0052 14.0016C13.982 14.0016 15.8895 14.7259 17.3633 16.0362C18.8371 17.3465 19.7738 19.1508 19.9944 21.1045C20.0218 21.3501 20.1396 21.5769 20.3252 21.7411C20.5108 21.9054 20.751 21.9955 20.9994 21.994H21.1099C21.3734 21.9638 21.6141 21.8314 21.7798 21.6254C21.9454 21.4195 22.0225 21.1568 21.9943 20.8946C21.7928 19.0918 21.1018 17.3776 19.9953 15.9357C18.8888 14.4937 17.4087 13.3784 15.7134 12.7093ZM11.9851 11.9997C11.1901 11.9997 10.4129 11.7653 9.75184 11.326C9.0908 10.8867 8.57557 10.2624 8.27133 9.53189C7.96708 8.8014 7.88748 7.9976 8.04258 7.22212C8.19768 6.44665 8.58053 5.73433 9.1427 5.17524C9.70487 4.61615 10.4211 4.23541 11.2009 4.08115C11.9806 3.9269 12.7889 4.00607 13.5234 4.30865C14.2579 4.61122 14.8857 5.12362 15.3274 5.78103C15.7691 6.43845 16.0048 7.21137 16.0048 8.00204C16.0048 9.06229 15.5813 10.0791 14.8275 10.8288C14.0736 11.5785 13.0512 11.9997 11.9851 11.9997Z" fill={isLightTheme ? "#656565" : '#FFFF'} />
					</svg>
				</div>
				<div className={isLightTheme ? menuLight.aboutUs : menu.aboutUs}>
					<span className={isLightTheme ? menuLight.txt : menu.txt}>О нас</span>
				</div>
				<div className={isLightTheme ? menuLight.help : menu.help}>
					<span className={isLightTheme ? menuLight.txt : menu.txt}>Помощь</span>
				</div>

			</div>, document.body
		)

	);
};