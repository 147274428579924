import { useEffect, useState } from "react";
import cl from "./message.module.css";
import api from "../../../api/ApiToken";
import { MessageFile } from "../messageFile/messageFile";

export const Message = ({ isUserMessage, text, file }) => {
	const [userName, setUserName] = useState("");

	useEffect(() => {
		const getUserName = async () => {
			try {
				const userName = await api.getUserName();
				if (userName) setUserName(userName);
			} catch (e) {
				console.log(e);
			}
		};
		getUserName();
	}, []);

	const userMsgBorderStyle = "10px 10px 0 10px";
	const botMsgBorderStyle = "10px 10px 10px 0";
	return (
		<div className={isUserMessage ? cl.userMessage : cl.botMessage}>
			<div
				style={{
					borderRadius: isUserMessage ? userMsgBorderStyle : botMsgBorderStyle,
					backgroundColor: isUserMessage ? "rgba(221, 233, 255, 1)" : "white",
				}}
				className={cl.message}
			>
				{isUserMessage ? (
					<p className={cl.userMessage_header}>{userName}</p>
				) : (
					<p className={cl.botMessage_header}>AI StableDraw</p>
				)}
				<p className={cl.message_txt}>{text}</p>
				{file && <MessageFile file={file} isUserMessage={isUserMessage} />}
			</div>
		</div>
	);
};
