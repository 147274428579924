import { useFilePreview } from "../hooks/useFilePreview";
import cl from "./inputFile.module.css";
import { bytesToSize } from "../../../shared/utils/byteToSizeConverter";

export const InputFile = ({ file, dispatch }) => {
	const { filePreviewUrl } = useFilePreview(file);

	const fileNameValidation = (fileName) => {
		return `${fileName.substring(0, 36)}${fileName.length > 36 ? "..." : ""}`;
	};

	return (
		<div className={cl.file}>
			<img
				className={cl.file_preview}
				src={filePreviewUrl}
				alt="Превью загруженного файла"
			/>
			<div className={cl.file_info}>
				<p title={file.name} className={cl.file_name}>
					{fileNameValidation(file.name)}
				</p>
				<p title={`${file.size} байт`} className={cl.file_size}>
					{bytesToSize(file.size)}
				</p>
			</div>
			<button
				title="Открепить файл"
				onClick={() => dispatch({ type: "setFile", newFile: null })}
				className={cl.deleteFileBtn}
			>
				<img
					className={cl.deleteFileIcon}
					src="./chatBotWindow/deleteFile.svg"
					alt="Открепить файл"
				/>
			</button>
		</div>
	);
};
