import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

import Destroy from "../LableBar/Destroy.jsx";
import Visability from "./ListItem.utils/Visability.jsx";

import cl from "./ListItem.module.css";

import canvasList from "../../../../store/canvasList.tsx";
import canvasState from "../../../../store/canvasState.tsx";

const ListItem = observer(({ item }) => {
	const canvasRef = useRef(null);
	useEffect(() => {
		if (item.active === true) {
			canvasState.canvas = canvasRef.current;
			canvasList.setActiveCanvas(canvasRef.current, item.id);
		}
	}, []);
	if (item.active === true) {
		canvasList.activeCanvas = canvasRef.current;
	}

	const setActiveCanvas = () => {
		if (item.active === true) {
			return;
		}
		canvasList.setActiveCanvas(canvasRef.current, item.id);
		let image = new Image();
		image.src = canvasList.activeCanvas.toDataURL();
		image.onload = () => {
			const ctxMain = canvasState.canvas.getContext("2d");

			ctxMain.clearRect(0, 0, ctxMain.canvas.offsetWidth, ctxMain.canvas.offsetHeight);
			ctxMain.drawImage(
				image,
				0,
				0,
				ctxMain.canvas.offsetWidth,
				ctxMain.canvas.offsetHeight
			);
		};
		canvasState.setCanvas(canvasRef.current);
	};

	return (
		<div
			className={[item.id === canvasList.activeCanvasValue ? cl.layerActive : cl.layer]}
			onClick={() => setActiveCanvas()}
		>
			<div className={cl.layer__block}>
				<Visability ids={item.id} />
				<div className={cl.layer_display_icon}>
					<canvas ref={canvasRef} id={item.id} className={cl.canvas}></canvas>
				</div>
				<span className={cl.name}></span>
			</div>
			<Destroy item={item} />
		</div>
	);
});

export default ListItem;
