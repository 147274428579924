import modelCard from '../stylesDark/sizeBar.module.css'


export const SkeletonCard = () => {
	

	return(
		<div className={modelCard.skeletonModelCard}>

		</div>
	);
}