import cl from "./chatWindow.module.css";
import { Header } from "../header/header";
import { MessageInput } from "../messageInput/messageInput";
import { MessageArea } from "../messageArea/messageArea";
import { useState } from "react";

export const ChatWindow = ({ setIsOpen }) => {
	const [messages, setMessages] = useState([]);

	return (
		<dialog className={cl.chatWindow} aria-label="Окно чата с ботом">
			<Header setIsOpen={setIsOpen} setMessages={setMessages} />
			<MessageArea messages={messages} />
			<MessageInput setMessages={setMessages} messages={messages} />
		</dialog>
	);
};
