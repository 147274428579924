import { useState, useEffect, useMemo } from "react";

export const useFilePreview = (file) => {
	const [filePreviewUrl, setFilePreviewUrl] = useState("");

	useEffect(() => {
		setPreview(file);

		return () => {
			// Очистка URL при удалении файла
			if (file && file.type.match(/image\/*/)) URL.revokeObjectURL(filePreviewUrl);
		};
	}, [file]);

	const setPreview = (file) => {
		if (!file) return;

		if (file.type.match(/audio\/*/))
			setFilePreviewUrl("./chatBotWindow/audioPreview.svg");

		if (file.type.match(/image\/*/)) setFilePreviewUrl(URL.createObjectURL(file));

		if (file.type.match(/video\/*/))
			setFilePreviewUrl("./chatBotWindow/videoPreview.svg");
	};

	const memoizedFilePreviewUrl = useMemo(() => filePreviewUrl, [filePreviewUrl]);

	return { filePreviewUrl: memoizedFilePreviewUrl };
};
