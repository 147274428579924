import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import canvasList from "../../../store/canvasList.tsx";
import canvasState from "../../../store/canvasState.tsx";
import toolState from "../../../store/toolState.tsx";
import Brush from "../toolsMobx/Brush";
import cl from "./Canvas.module.css";
const Canvas = observer(() => {
	const width = canvasState.width;
	const height = canvasState.height;
	const opacity = canvasState.opacity;
	const visibility = canvasState.visable;
	const styles = {
		zIndex: 0,
		position: "absolute",
		backgroundColor: "white",
		touchAction: "none",
		userSelect: "none",
		opacity: opacity,
		visibility: visibility ? "visible" : "hidden",
	};

	const canvasRef = useRef(null);

	useEffect(() => {
		canvasState.setCanvas(canvasRef.current);
		toolState.setTool(new Brush(canvasRef.current));
	}, [canvasState.canvas]);
	const mouseMoveHandler = (e) => {
		let image = new Image();
		image.src = canvasState.canvas.toDataURL("image/png", 0.5);
		image.onload = () => {
			const ctx = canvasState.canvas.getContext("2d");
			const ctx2 = canvasList.activeCanvas.getContext("2d");

			ctx.clearRect(0, 0, ctx.canvas.offsetWidth, ctx.canvas.offsetHeight);
			ctx.drawImage(image, 0, 0, ctx.canvas.offsetWidth, ctx.canvas.offsetHeight);
			ctx2.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
			ctx2.drawImage(image, 0, 0, ctx2.canvas.width, ctx2.canvas.height);
		};
	};

	const mouseDownHandler = () => {
		let image = new Image();
		canvasState.pushToUndo(canvasState.canvas.toDataURL());
		image.src = canvasState.canvas.toDataURL();
		image.onload = () => {
			const ctx = canvasState.canvas.getContext("2d");

			ctx.clearRect(0, 0, ctx.canvas.offsetWidth, ctx.canvas.offsetHeight);
			ctx.drawImage(image, 0, 0, ctx.canvas.offsetWidth, ctx.canvas.offsetHeight);
		};
		canvasState.setImgSrc(canvasState.canvas.toDataURL());
	};
	return (
		<div
			className={cl.v_frame}
			onMouseDown={() => mouseDownHandler()}
			onMouseMove={(e) => mouseMoveHandler(e)}
			style={{
				width: `${width}px`,
				height: `${height}px`,
				backgroundColor: "transparent",
			}}
			id="v_frame"
		>
			<canvas
				ref={canvasRef}
				width={`${width}px`}
				height={`${height}px`}
				style={styles}
			></canvas>
			<div></div>
		</div>
	);
});
export default Canvas;
