import cl from "./messageInput.module.css";
import { useReducer, useRef, useState } from "react";
import { InputFile } from "../inputFile/inputFile";
import api from "../../../api/chatBot";

const reducer = (state, action) => {
	switch (action.type) {
		case "setFile":
			return { ...state, file: action.newFile };
		case "setText":
			return { ...state, text: action.newText };
		case "setIsUserMessage":
			return { ...state, isUserMessage: action.IsUserMessage };
		default:
			throw Error("Unknown action.");
	}
};
export const MessageInput = ({ setMessages, messages }) => {
	const [message, dispatch] = useReducer(reducer, {
		text: "",
		isUserMessage: true,
		file: null,
	});
	const [isLoading, setIsLoading] = useState(false);
	const textareaRef = useRef(null);

	const changeTextHandler = (e) => {
		dispatch({ type: "setText", newText: e.target.value });
		dispatch({ type: "setIsUserMessage", IsUserMessage: true });
	};

	const sendMessage = () => {
		if (!message.text && !message.file) return;

		setIsLoading(true);
		//Отображаем сообщение в списке отправленных сообщений
		setMessages([...messages, message]);

		//Очищаем поле ввода
		textareaRef.current.value = "";
		dispatch({ type: "setText", newText: "" });
		dispatch({ type: "setFile", newFile: null });

		//Формируем форму для отправки на сервер
		const messageFormData = new FormData();
		messageFormData.append("text", message.text);

		if (message.file) {
			const blobFile = new Blob([message.file], { type: message.file.type });
			messageFormData.append("file", blobFile);
		} else messageFormData.append("file", null);

		api
			.GetAnswer(messageFormData)
			.then((res) => {
				setMessages((prevMessages) => [
					...prevMessages,
					{ text: res.data.text, isUserMessage: false, file: null },
				]);
				setIsLoading(false);
				console.log(res);
			})
			.catch((e) => {
				setMessages((prevMessages) => [
					...prevMessages,
					{ text: "Произошла ошибка :(", isUserMessage: false, file: null },
				]);
				setIsLoading(false);

				console.log(e);
			});
	};

	const changeFileHandler = (e) => {
		dispatch({ type: "setFile", newFile: e.target.files[0] });
		dispatch({ type: "setIsUserMessage", IsUserMessage: true });
	};

	return (
		<>
			{message.file && <InputFile file={message.file} dispatch={dispatch} />}
			<div className={cl.messageInput}>
				<label htmlFor="clip" title="Прикрепить файл" className={cl.clipBtn}>
					<img className={cl.clip} src="./chatBotWindow/clip.svg" alt="Прикрепить файл" />
				</label>

				<input
					accept="image/*, audio/*, video/*"
					onChange={changeFileHandler}
					multiple={false}
					type="file"
					id="clip"
					className={cl.clipInput}
				/>

				<textarea
					rows={1}
					wrap="physical"
					ref={textareaRef}
					onChange={changeTextHandler}
					placeholder="Написать сообщение..."
					className={cl.messageInput_textarea}
				></textarea>

				{isLoading ? (
					<img
						className={cl.loading}
						alt="Идёт обновление контекста"
						src="./chatBotWindow/sendLoading.svg"
					/>
				) : (
					<button
						onClick={sendMessage}
						className={cl.sendBtn}
						title="Отправить сообщение"
					>
						<img
							className={cl.send}
							src="./chatBotWindow/send.svg"
							alt="Отправить сообщение"
						/>
					</button>
				)}
			</div>
		</>
	);
};
