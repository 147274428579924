import React from "react";
import canvasState from "../../../../store/canvasState.tsx";
import cl from "./RightArrow.module.css";
const RightArrow = () => {
	const Redo = () => {
		canvasState.redo();
	};
	return (
		<button className={cl.right__btn} onClick={Redo} title={"Повторить"}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="75"
				height="50"
				viewBox="75 13 75 25"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M115.687 20.8046L111.976 23.6384C111.745 23.8162 111.599 24.0717 111.57 24.3491C111.542 24.6265 111.632 24.9031 111.823 25.1185C111.927 25.2429 112.061 25.3439 112.213 25.4141C112.365 25.4843 112.532 25.5219 112.702 25.524C112.963 25.5236 113.215 25.4399 113.417 25.287L119.493 20.6267C119.554 20.5887 119.612 20.5451 119.664 20.4961C119.839 20.3335 119.951 20.1232 119.985 19.898C120.011 19.7486 120.003 19.5949 119.958 19.4477C119.895 19.2379 119.764 19.0514 119.582 18.9139L113.417 14.2368C113.184 14.0608 112.886 13.9775 112.587 14.0052C112.289 14.0329 112.014 14.1693 111.824 14.3845C111.633 14.5997 111.542 14.8762 111.571 15.1536C111.599 15.4309 111.745 15.6864 111.976 15.8643L115.691 18.6978H102.089C99.6584 18.7535 97.347 19.6894 95.6488 21.3058C93.9506 22.9221 93 25.0908 93 27.3489C93 29.607 93.9506 31.7757 95.6488 33.392C97.347 35.0083 99.6584 35.9442 102.089 35.9999H118.862C119.163 35.9999 119.452 35.889 119.664 35.6914C119.877 35.4939 119.997 35.2259 119.997 34.9465C119.997 34.6672 119.877 34.3992 119.664 34.2017C119.452 34.0041 119.163 33.8931 118.862 33.8931H102.089C100.255 33.8448 98.513 33.1341 97.2338 31.9121C95.9546 30.6901 95.2389 29.0531 95.2389 27.3489C95.2389 25.6447 95.9546 24.0077 97.2338 22.7857C98.513 21.5636 100.255 20.853 102.089 20.8046H115.687Z"
					fill="#656565"
				></path>
			</svg>
		</button>
	);
};
export default RightArrow;
