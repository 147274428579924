import cl from "./genTypeToggleBtn.module.css";
import { useContext } from "react";
import { generateTypeContext } from "../generateWindow/generateWindow";

export const GenTypeToggleBtn = ({ setIsAudio }) => {
	const isAudio = useContext(generateTypeContext);

	const handleToggle = () => {
		setIsAudio(!isAudio);
	};

	return (
		<div className={cl.toggleBtn}>
			<label htmlFor="toggleGenType" className={cl.label_toggleBtn}>
				<p
					title="Генерация видео"
					onClick={handleToggle}
					className={`${cl.genTypeTxt} ${!isAudio ? cl.active : ""}`}
				>
					Видео
				</p>
				<p
					title="Генерация аудио"
					onClick={handleToggle}
					className={`${cl.genTypeTxt} ${!isAudio ? cl.active : ""}`}
				>
					Аудио
				</p>
			</label>
			<input
				id="toggleGenType"
				className={cl.hiddenInput}
				type="checkbox"
				checked={isAudio}
				onChange={handleToggle}
			/>

			<div className={`${cl.toggleSlider} ${isAudio ? cl.checked : ""}`}></div>
		</div>
	);
};
