import React from 'react';
import cl from './SaveButton.module.css'
import { InputLabel } from '@mui/material';
import canvasState from '../../../../store/canvasState.tsx';
import { observer } from 'mobx-react-lite';
const SaveButton = observer(() => {
    const img = new Image()
    img.src = canvasState.getImgSrc()
    return (
        <div >
            <InputLabel className={cl.upload}>
                <a 
                    href={img.src}
                    download="StableDrawImg"
                    target="_blank"
                    rel="noreferrer"
                >
                <p>Скачать</p>
                </a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M21 14.439C20.7348 14.439 20.4804 14.5418 20.2929 14.7248C20.1054 14.9077 20 15.1559 20 15.4146V19.3171C20 19.5758 19.8946 19.824 19.7071 20.0069C19.5196 20.1899 19.2652 20.2927 19 20.2927H5C4.73478 20.2927 4.48043 20.1899 4.29289 20.0069C4.10536 19.824 4 19.5758 4 19.3171V15.4146C4 15.1559 3.89464 14.9077 3.70711 14.7248C3.51957 14.5418 3.26522 14.439 3 14.439C2.73478 14.439 2.48043 14.5418 2.29289 14.7248C2.10536 14.9077 2 15.1559 2 15.4146V19.3171C2 20.0933 2.31607 20.8378 2.87868 21.3867C3.44129 21.9355 4.20435 22.2439 5 22.2439H19C19.7956 22.2439 20.5587 21.9355 21.1213 21.3867C21.6839 20.8378 22 20.0933 22 19.3171V15.4146C22 15.1559 21.8946 14.9077 21.7071 14.7248C21.5196 14.5418 21.2652 14.439 21 14.439ZM11.29 16.1073C11.3851 16.1961 11.4972 16.2658 11.62 16.3122C11.7397 16.3638 11.8691 16.3905 12 16.3905C12.1309 16.3905 12.2603 16.3638 12.38 16.3122C12.5028 16.2658 12.6149 16.1961 12.71 16.1073L16.71 12.2049C16.8983 12.0212 17.0041 11.772 17.0041 11.5122C17.0041 11.2524 16.8983 11.0032 16.71 10.8195C16.5217 10.6358 16.2663 10.5326 16 10.5326C15.7337 10.5326 15.4783 10.6358 15.29 10.8195L13 13.0634V3.70731C13 3.44857 12.8946 3.20042 12.7071 3.01745C12.5196 2.83449 12.2652 2.7317 12 2.7317C11.7348 2.7317 11.4804 2.83449 11.2929 3.01745C11.1054 3.20042 11 3.44857 11 3.70731V13.0634L8.71 10.8195C8.61676 10.7285 8.50607 10.6564 8.38425 10.6072C8.26243 10.5579 8.13186 10.5326 8 10.5326C7.86814 10.5326 7.73757 10.5579 7.61575 10.6072C7.49393 10.6564 7.38324 10.7285 7.29 10.8195C7.19676 10.9105 7.1228 11.0185 7.07234 11.1373C7.02188 11.2562 6.99591 11.3835 6.99591 11.5122C6.99591 11.6408 7.02188 11.7682 7.07234 11.8871C7.1228 12.0059 7.19676 12.1139 7.29 12.2049L11.29 16.1073Z" fill="white" />
                </svg>
            </InputLabel>
           
		</div>
    )
})
export default SaveButton