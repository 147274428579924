// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "pbrBlockSubSurface";
const shader = `struct subSurfaceOutParams{vec3 specularEnvironmentReflectance;#ifdef SS_REFRACTION
vec3 finalRefraction;vec3 surfaceAlbedo;#ifdef SS_LINKREFRACTIONTOTRANSPARENCY
float alpha;#endif
#ifdef REFLECTION
float refractionFactorForIrradiance;#endif
#endif
#ifdef SS_TRANSLUCENCY
vec3 transmittance;float translucencyIntensity;#ifdef REFLECTION
vec3 refractionIrradiance;#endif
#endif
#if DEBUGMODE>0
#ifdef SS_THICKNESSANDMASK_TEXTURE
vec4 thicknessMap;#endif
#ifdef SS_REFRACTION
vec4 environmentRefraction;vec3 refractionTransmittance;#endif
#endif
};#ifdef SUBSURFACE
#define pbr_inline
#define inline
void subSurfaceBlock(in vec3 vSubSurfaceIntensity,in vec2 vThicknessParam,in vec4 vTintColor,in vec3 normalW,in vec3 specularEnvironmentReflectance,#ifdef SS_THICKNESSANDMASK_TEXTURE
in vec4 thicknessMap,#endif
#ifdef SS_REFRACTIONINTENSITY_TEXTURE
in vec4 refractionIntensityMap,#endif
#ifdef SS_TRANSLUCENCYINTENSITY_TEXTURE
in vec4 translucencyIntensityMap,#endif
#ifdef REFLECTION
#ifdef SS_TRANSLUCENCY
in mat4 reflectionMatrix,#ifdef USESPHERICALFROMREFLECTIONMAP
#if !defined(NORMAL) || !defined(USESPHERICALINVERTEX)
in vec3 irradianceVector_,#endif
#if defined(REALTIME_FILTERING)
in samplerCube reflectionSampler,in vec2 vReflectionFilteringInfo,#endif
#endif
#ifdef USEIRRADIANCEMAP
#ifdef REFLECTIONMAP_3D
in samplerCube irradianceSampler,#else
in sampler2D irradianceSampler,#endif
#endif
#endif
#endif
#if defined(SS_REFRACTION) || defined(SS_TRANSLUCENCY)
in vec3 surfaceAlbedo,#endif
#ifdef SS_REFRACTION
in vec3 vPositionW,in vec3 viewDirectionW,in mat4 view,in vec4 vRefractionInfos,in mat4 refractionMatrix,in vec4 vRefractionMicrosurfaceInfos,in vec4 vLightingIntensity,#ifdef SS_LINKREFRACTIONTOTRANSPARENCY
in float alpha,#endif
#ifdef SS_LODINREFRACTIONALPHA
in float NdotVUnclamped,#endif
#ifdef SS_LINEARSPECULARREFRACTION
in float roughness,#endif
in float alphaG,#ifdef SS_REFRACTIONMAP_3D
in samplerCube refractionSampler,#ifndef LODBASEDMICROSFURACE
in samplerCube refractionSamplerLow,in samplerCube refractionSamplerHigh,#endif
#else
in sampler2D refractionSampler,#ifndef LODBASEDMICROSFURACE
in sampler2D refractionSamplerLow,in sampler2D refractionSamplerHigh,#endif
#endif
#ifdef ANISOTROPIC
in anisotropicOutParams anisotropicOut,#endif
#ifdef REALTIME_FILTERING
in vec2 vRefractionFilteringInfo,#endif
#ifdef SS_USE_LOCAL_REFRACTIONMAP_CUBIC
in vec3 refractionPosition,in vec3 refractionSize,#endif
#endif
#ifdef SS_TRANSLUCENCY
in vec3 vDiffusionDistance,#endif
out subSurfaceOutParams outParams){outParams.specularEnvironmentReflectance=specularEnvironmentReflectance;#ifdef SS_REFRACTION
float refractionIntensity=vSubSurfaceIntensity.x;#ifdef SS_LINKREFRACTIONTOTRANSPARENCY
refractionIntensity*=(1.0-alpha);outParams.alpha=1.0;#endif
#endif
#ifdef SS_TRANSLUCENCY
float translucencyIntensity=vSubSurfaceIntensity.y;#endif
#ifdef SS_THICKNESSANDMASK_TEXTURE
#if defined(SS_USE_GLTF_TEXTURES)
float thickness=thicknessMap.g*vThicknessParam.y+vThicknessParam.x;#else
float thickness=thicknessMap.r*vThicknessParam.y+vThicknessParam.x;#endif
#if DEBUGMODE>0
outParams.thicknessMap=thicknessMap;#endif
#ifdef SS_MASK_FROM_THICKNESS_TEXTURE
#if defined(SS_REFRACTION) && defined(SS_REFRACTION_USE_INTENSITY_FROM_TEXTURE)
#if defined(SS_USE_GLTF_TEXTURES)
refractionIntensity*=thicknessMap.r;#else
refractionIntensity*=thicknessMap.g;#endif
#endif
#if defined(SS_TRANSLUCENCY) && defined(SS_TRANSLUCENCY_USE_INTENSITY_FROM_TEXTURE)
translucencyIntensity*=thicknessMap.b;#endif
#endif
#else
float thickness=vThicknessParam.y;#endif
#ifdef SS_REFRACTIONINTENSITY_TEXTURE
#ifdef SS_USE_GLTF_TEXTURES
refractionIntensity*=refractionIntensityMap.r;#else
refractionIntensity*=refractionIntensityMap.g;#endif
#endif
#ifdef SS_TRANSLUCENCYINTENSITY_TEXTURE
translucencyIntensity*=translucencyIntensityMap.b;#endif
#ifdef SS_TRANSLUCENCY
thickness=maxEps(thickness);vec3 transmittance=transmittanceBRDF_Burley(vTintColor.rgb,vDiffusionDistance,thickness);transmittance*=translucencyIntensity;outParams.transmittance=transmittance;outParams.translucencyIntensity=translucencyIntensity;#endif
#ifdef SS_REFRACTION
vec4 environmentRefraction=vec4(0.,0.,0.,0.);#ifdef ANISOTROPIC
vec3 refractionVector=refract(-viewDirectionW,anisotropicOut.anisotropicNormal,vRefractionInfos.y);#else
vec3 refractionVector=refract(-viewDirectionW,normalW,vRefractionInfos.y);#endif
#ifdef SS_REFRACTIONMAP_OPPOSITEZ
refractionVector.z*=-1.0;#endif
#ifdef SS_REFRACTIONMAP_3D
#ifdef SS_USE_LOCAL_REFRACTIONMAP_CUBIC
refractionVector=parallaxCorrectNormal(vPositionW,refractionVector,refractionSize,refractionPosition);#endif
refractionVector.y=refractionVector.y*vRefractionInfos.w;vec3 refractionCoords=refractionVector;refractionCoords=vec3(refractionMatrix*vec4(refractionCoords,0));#else
#ifdef SS_USE_THICKNESS_AS_DEPTH
vec3 vRefractionUVW=vec3(refractionMatrix*(view*vec4(vPositionW+refractionVector*thickness,1.0)));#else
vec3 vRefractionUVW=vec3(refractionMatrix*(view*vec4(vPositionW+refractionVector*vRefractionInfos.z,1.0)));#endif
vec2 refractionCoords=vRefractionUVW.xy/vRefractionUVW.z;refractionCoords.y=1.0-refractionCoords.y;#endif
#ifdef SS_HAS_THICKNESS
float ior=vRefractionInfos.y;#else
float ior=vRefractionMicrosurfaceInfos.w;#endif
#ifdef SS_LODINREFRACTIONALPHA
float refractionAlphaG=alphaG;refractionAlphaG=mix(alphaG,0.0,clamp(ior*3.0-2.0,0.0,1.0));float refractionLOD=getLodFromAlphaG(vRefractionMicrosurfaceInfos.x,refractionAlphaG,NdotVUnclamped);#elif defined(SS_LINEARSPECULARREFRACTION)
float refractionRoughness=alphaG;refractionRoughness=mix(alphaG,0.0,clamp(ior*3.0-2.0,0.0,1.0));float refractionLOD=getLinearLodFromRoughness(vRefractionMicrosurfaceInfos.x,refractionRoughness);#else
float refractionAlphaG=alphaG;refractionAlphaG=mix(alphaG,0.0,clamp(ior*3.0-2.0,0.0,1.0));float refractionLOD=getLodFromAlphaG(vRefractionMicrosurfaceInfos.x,refractionAlphaG);#endif
#ifdef LODBASEDMICROSFURACE
refractionLOD=refractionLOD*vRefractionMicrosurfaceInfos.y+vRefractionMicrosurfaceInfos.z;#ifdef SS_LODINREFRACTIONALPHA
float automaticRefractionLOD=UNPACK_LOD(sampleRefraction(refractionSampler,refractionCoords).a);float requestedRefractionLOD=max(automaticRefractionLOD,refractionLOD);#else
float requestedRefractionLOD=refractionLOD;#endif
#if defined(REALTIME_FILTERING) && defined(SS_REFRACTIONMAP_3D)
environmentRefraction=vec4(radiance(alphaG,refractionSampler,refractionCoords,vRefractionFilteringInfo),1.0);#else
environmentRefraction=sampleRefractionLod(refractionSampler,refractionCoords,requestedRefractionLOD);#endif
#else
float lodRefractionNormalized=saturate(refractionLOD/log2(vRefractionMicrosurfaceInfos.x));float lodRefractionNormalizedDoubled=lodRefractionNormalized*2.0;vec4 environmentRefractionMid=sampleRefraction(refractionSampler,refractionCoords);if (lodRefractionNormalizedDoubled<1.0){environmentRefraction=mix(sampleRefraction(refractionSamplerHigh,refractionCoords),environmentRefractionMid,lodRefractionNormalizedDoubled);} else {environmentRefraction=mix(environmentRefractionMid,sampleRefraction(refractionSamplerLow,refractionCoords),lodRefractionNormalizedDoubled-1.0);}#endif
#ifdef SS_RGBDREFRACTION
environmentRefraction.rgb=fromRGBD(environmentRefraction);#endif
#ifdef SS_GAMMAREFRACTION
environmentRefraction.rgb=toLinearSpace(environmentRefraction.rgb);#endif
environmentRefraction.rgb*=vRefractionInfos.x;#endif
#ifdef SS_REFRACTION
vec3 refractionTransmittance=vec3(refractionIntensity);#ifdef SS_THICKNESSANDMASK_TEXTURE
vec3 volumeAlbedo=computeColorAtDistanceInMedia(vTintColor.rgb,vTintColor.w);refractionTransmittance*=cocaLambert(volumeAlbedo,thickness);#elif defined(SS_LINKREFRACTIONTOTRANSPARENCY)
float maxChannel=max(max(surfaceAlbedo.r,surfaceAlbedo.g),surfaceAlbedo.b);vec3 volumeAlbedo=saturate(maxChannel*surfaceAlbedo);environmentRefraction.rgb*=volumeAlbedo;#else
vec3 volumeAlbedo=computeColorAtDistanceInMedia(vTintColor.rgb,vTintColor.w);refractionTransmittance*=cocaLambert(volumeAlbedo,vThicknessParam.y);#endif
#ifdef SS_ALBEDOFORREFRACTIONTINT
environmentRefraction.rgb*=surfaceAlbedo.rgb;#endif
outParams.surfaceAlbedo=surfaceAlbedo*(1.-refractionIntensity);#ifdef REFLECTION
outParams.refractionFactorForIrradiance=(1.-refractionIntensity);#endif
#ifdef UNUSED_MULTIPLEBOUNCES
vec3 bounceSpecularEnvironmentReflectance=(2.0*specularEnvironmentReflectance)/(1.0+specularEnvironmentReflectance);outParams.specularEnvironmentReflectance=mix(bounceSpecularEnvironmentReflectance,specularEnvironmentReflectance,refractionIntensity);#endif
refractionTransmittance*=1.0-outParams.specularEnvironmentReflectance;#if DEBUGMODE>0
outParams.refractionTransmittance=refractionTransmittance;#endif
outParams.finalRefraction=environmentRefraction.rgb*refractionTransmittance*vLightingIntensity.z;#if DEBUGMODE>0
outParams.environmentRefraction=environmentRefraction;#endif
#endif
#if defined(REFLECTION) && defined(SS_TRANSLUCENCY)
#if defined(NORMAL) && defined(USESPHERICALINVERTEX) || !defined(USESPHERICALFROMREFLECTIONMAP)
vec3 irradianceVector=vec3(reflectionMatrix*vec4(normalW,0)).xyz;#ifdef REFLECTIONMAP_OPPOSITEZ
irradianceVector.z*=-1.0;#endif
#ifdef INVERTCUBICMAP
irradianceVector.y*=-1.0;#endif
#else
vec3 irradianceVector=irradianceVector_;#endif
#if defined(USESPHERICALFROMREFLECTIONMAP)
#if defined(REALTIME_FILTERING)
vec3 refractionIrradiance=irradiance(reflectionSampler,-irradianceVector,vReflectionFilteringInfo);#else
vec3 refractionIrradiance=computeEnvironmentIrradiance(-irradianceVector);#endif
#elif defined(USEIRRADIANCEMAP)
#ifdef REFLECTIONMAP_3D
vec3 irradianceCoords=irradianceVector;#else
vec2 irradianceCoords=irradianceVector.xy;#ifdef REFLECTIONMAP_PROJECTION
irradianceCoords/=irradianceVector.z;#endif
irradianceCoords.y=1.0-irradianceCoords.y;#endif
vec4 refractionIrradiance=sampleReflection(irradianceSampler,-irradianceCoords);#ifdef RGBDREFLECTION
refractionIrradiance.rgb=fromRGBD(refractionIrradiance);#endif
#ifdef GAMMAREFLECTION
refractionIrradiance.rgb=toLinearSpace(refractionIrradiance.rgb);#endif
#else
vec4 refractionIrradiance=vec4(0.);#endif
refractionIrradiance.rgb*=transmittance;#ifdef SS_ALBEDOFORTRANSLUCENCYTINT
refractionIrradiance.rgb*=surfaceAlbedo.rgb;#endif
outParams.refractionIrradiance=refractionIrradiance.rgb;#endif
}#endif
`;
// Sideeffect
ShaderStore.IncludesShadersStore[name] = shader;
/** @internal */
export const pbrBlockSubSurface = { name, shader };
