import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import canvasState from "../../../../store/canvasState.tsx";
import ListLayers from "../ListLayers/ListLayers.jsx";
import Add from "./Add";
import cl from "./LableBar.module.css";
import Merge from "./Merge";

const LableBar = observer((props) => {
	// new logic
	const [range, setRange] = useState(0);
	const Grad = (e) => {
		e.preventDefault();
		canvasState.opacity = 1 - e.target.value / 100;
		setRange(e.target.value);
	};

	return (
		<div className={cl.layers}>
			<label
				className={cl.opacity__block}
				style={{ background: `linear-gradient(to right, #93BBE3 ${range}%, #ABABAB 0%)` }}
			>
				<span className={cl.opacity__text}>Прозрачность: {range}%</span>
				<input type="range" style={{ opacity: 0 }} onChange={(e) => Grad(e)} />
			</label>
			<ListLayers />
			<div>
				<div className={cl.scale__block}>
					<input
						className={cl.input__scale}
						onChange={(e) => canvasState.setHeight(e.target.value)}
						defaultValue={canvasState.height}
						placeholder="Высота"
						type="number"
					/>

					<input
						className={cl.input__scale}
						onChange={(e) => canvasState.setWidth(e.target.value)}
						defaultValue={canvasState.width}
						placeholder="Ширина"
						type="number"
					/>
				</div>
				<div className={cl.layers__button}>
					<div className={cl.left__button_group}>
						{/*<Swap/>*/}
						<Add />
						<Merge />
					</div>
				</div>
			</div>
		</div>
	);
});

export default LableBar;
