import React from "react";
import canvasState from "../../../../store/canvasState.tsx";
import cl from "./LeftArrow.module.css";
const LeftArrow = () => {
	const Undo = () => {
		canvasState.undo();
	};
	return (
		<button className={cl.left__btn} onClick={Undo} title={"Отменить"}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="75"
				height="50"
				viewBox="0 0 75 50"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M36.5077 20.6273C36.4463 20.5892 36.3887 20.5454 36.3357 20.4962C36.1604 20.3334 36.0484 20.1229 36.0144 19.8976C35.9885 19.7483 35.9975 19.5948 36.0416 19.4477C36.1045 19.2379 36.2358 19.0514 36.4175 18.9139L42.5832 14.2368C42.8158 14.0608 43.114 13.9775 43.4125 14.0052C43.711 14.0329 43.9856 14.1693 44.1761 14.3845C44.3666 14.5997 44.4576 14.8762 44.429 15.1536C44.4005 15.4309 44.2549 15.6864 44.0239 15.8643L40.3086 18.6979H53.9105C56.3416 18.7536 58.6529 19.6895 60.3512 21.3058C62.0494 22.9221 63 25.0908 63 27.349C63 29.6071 62.0494 31.7758 60.3512 33.3921C58.6529 35.0084 56.3416 35.9443 53.9105 36H37.1379C36.837 36 36.5484 35.889 36.3357 35.6915C36.1229 35.4939 36.0034 35.226 36.0034 34.9466C36.0034 34.6672 36.1229 34.3993 36.3357 34.2017C36.5484 34.0042 36.837 33.8932 37.1379 33.8932H53.9105C55.745 33.8449 57.487 33.1342 58.7662 31.9122C60.0454 30.6902 60.761 29.0531 60.761 27.349C60.761 25.6448 60.0454 24.0077 58.7662 22.7857C57.487 21.5637 55.745 20.853 53.9105 20.8047H40.3127L44.024 23.6384C44.2551 23.8161 44.401 24.0716 44.4297 24.349C44.4584 24.6264 44.3676 24.9031 44.1771 25.1184C44.0726 25.2428 43.9394 25.3439 43.7872 25.4141C43.635 25.4843 43.4678 25.5218 43.2979 25.524C43.0374 25.5235 42.7851 25.4398 42.5832 25.287L36.5077 20.6273Z"
					fill="#656565"
				/>
			</svg>
		</button>
	);
};
export default LeftArrow;
