import React from "react";
import "./css/editor.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenerateBtn } from "../../generateVideoWindow/generateBtn/generateBtn";
import { GenerateWindow } from "../../generateVideoWindow/generateWindow/generateWindow";
import api from "../../../api/videoGeneration";
import {
	faVolumeMute,
	faVolumeUp,
	faPause,
	faPlay,
	faGripLinesVertical,
	faSync,
	faStepBackward,
	faStepForward,
	faCamera,
	faDownload,
	faEraser,
	faPlus,
	faArrowsSpin,
} from "@fortawesome/free-solid-svg-icons";

class Editor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMuted: false,
			playing: false,
			currently_grabbed: { index: 0, type: "none" },
			difference: 0.2,
			deletingGrabber: false,
			current_warning: null,
			imageUrl: "",
			isGenerateOpen: false,
			isCutLoading: false,
		};
		this.playVideo = React.createRef();
		this.progressBar = React.createRef();
		this.playBackBar = React.createRef();
	}

	setIsOpen = () => {
		this.setState({ isGenerateOpen: !this.state.isGenerateOpen });
	};

	warnings = {
		delete_grabber: (
			<div>Please click on the grabber (either start or end) to delete it</div>
		),
	};

	reader = new FileReader();

	componentDidUpdate(prevProps) {
		if (prevProps.videoUrl !== this.props.videoUrl) {
			this.playVideo.current.load(); // This will reload the video element
		}
	}

	componentDidMount = () => {
		// Check if video ended
		let self = this;
		this.playVideo.current.addEventListener("timeupdate", function () {
			let curr_idx = self.state.currently_grabbed.index;
			let seek =
				((self.playVideo.current.currentTime - self.props.timings[curr_idx].start) /
					self.playVideo.current.duration) *
				100;
			self.progressBar.current.style.width = `${seek}%`;
			if (
				self.playVideo.current.currentTime >=
				self.props.timings[self.props.timings.length - 1].end
			) {
				self.playVideo.current.pause();
				self.setState({ playing: false });
			} else if (self.playVideo.current.currentTime >= self.props.timings[curr_idx].end) {
				if (curr_idx + 1 < self.props.timings.length) {
					self.setState(
						{ currently_grabbed: { index: curr_idx + 1, type: "start" } },
						() => {
							self.progressBar.current.style.width = "0%";
							self.progressBar.current.style.left = `${
								(self.props.timings[curr_idx + 1].start /
									self.playVideo.current.duration) *
								100
							}%`;
							self.playVideo.current.currentTime = self.props.timings[curr_idx + 1].start;
						}
					);
				}
			}
		});

		window.addEventListener("keyup", function (event) {
			if (event.key === " ") {
				self.play_pause();
			}
		});
		let time = this.props.timings;
		this.playVideo.current.onloadedmetadata = () => {
			if (time.length === 0) {
				time.push({ start: 0, end: this.playVideo.current.duration });
				this.props.updateState({ timings: time }, () => {
					this.addActiveSegments();
				});
			} else {
				this.addActiveSegments();
			}
		};
	};

	reset = () => {
		this.playVideo.current.pause();
		this.setState({
			isMuted: false,
			playing: false,
			currently_grabbed: { index: 0, type: "none" },
			difference: 0.2,
			deletingGrabber: false,
			current_warning: null,
			imageUrl: "",
			isGenerateOpen: false,
		});
		this.props.updateState(
			{ timings: [{ start: 0, end: this.playVideo.current.duration }] },
			() => {
				this.playVideo.current.currentTime = this.props.timings[0].start;
				this.progressBar.current.style.left = `${
					(this.props.timings[0].start / this.playVideo.current.duration) * 100
				}%`;
				this.progressBar.current.style.width = "0%";
				this.addActiveSegments();
			}
		);
	};

	captureSnapshot = () => {
		if (this.props.fileType !== "video/mp4") return;

		let video = this.playVideo.current;
		const canvas = document.createElement("canvas");
		// scale the canvas accordingly
		canvas.width = video.videoWidth;
		canvas.height = video.videoHeight;
		// draw the video at that frame
		canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
		// convert it to a usable data URL
		const dataURL = canvas.toDataURL();
		this.setState({ imageUrl: dataURL });
	};

	downloadSnapshot = () => {
		let a = document.createElement("a"); //Create <a>
		a.href = this.state.imageUrl; //Image Base64 Goes here
		a.download = "Thumbnail.png"; //File name Here
		a.click(); //Downloaded file
	};

	skipPrevious = () => {
		if (this.state.playing) {
			this.playVideo.current.pause();
		}
		let prev_idx =
			this.state.currently_grabbed.index !== 0
				? this.state.currently_grabbed.index - 1
				: this.props.timings.length - 1;
		this.setState(
			{ currently_grabbed: { index: prev_idx, type: "start" }, playing: false },
			() => {
				this.progressBar.current.style.left = `${
					(this.props.timings[prev_idx].start / this.playVideo.current.duration) * 100
				}%`;
				this.progressBar.current.style.width = "0%";
				this.playVideo.current.currentTime = this.props.timings[prev_idx].start;
			}
		);
	};

	play_pause = () => {
		let self = this;
		if (this.state.playing) {
			this.playVideo.current.pause();
		} else {
			if (
				self.playVideo.current.currentTime >=
				self.props.timings[self.props.timings.length - 1].end
			) {
				self.playVideo.current.pause();
				self.setState(
					{ playing: false, currently_grabbed: { index: 0, type: "start" } },
					() => {
						self.playVideo.current.currentTime = self.props.timings[0].start;
						self.progressBar.current.style.left = `${
							(self.props.timings[0].start / self.playVideo.current.duration) * 100
						}%`;
						self.progressBar.current.style.width = "0%";
					}
				);
			}
			this.playVideo.current.play();
		}
		this.setState({ playing: !this.state.playing });
	};

	skipNext = () => {
		if (this.state.playing) {
			this.playVideo.current.pause();
		}
		let next_idx =
			this.state.currently_grabbed.index !== this.props.timings.length - 1
				? this.state.currently_grabbed.index + 1
				: 0;
		this.setState(
			{ currently_grabbed: { index: next_idx, type: "start" }, playing: false },
			() => {
				this.progressBar.current.style.left = `${
					(this.props.timings[next_idx].start / this.playVideo.current.duration) * 100
				}%`;
				this.progressBar.current.style.width = "0%";
				this.playVideo.current.currentTime = this.props.timings[next_idx].start;
			}
		);
	};

	updateProgress = (event) => {
		let playbackRect = this.playBackBar.current.getBoundingClientRect();
		let seekTime =
			((event.clientX - playbackRect.left) / playbackRect.width) *
			this.playVideo.current.duration;
		this.playVideo.current.pause();
		// find where seekTime is in the segment
		let index = -1;
		let counter = 0;
		for (let times of this.props.timings) {
			if (seekTime >= times.start && seekTime <= times.end) {
				index = counter;
			}
			counter += 1;
		}
		if (index === -1) {
			return;
		}
		this.setState(
			{ playing: false, currently_grabbed: { index: index, type: "start" } },
			() => {
				this.progressBar.current.style.width = "0%"; // Since the width is set later, this is necessary to hide weird UI
				this.progressBar.current.style.left = `${
					(this.props.timings[index].start / this.playVideo.current.duration) * 100
				}%`;
				this.playVideo.current.currentTime = seekTime;
			}
		);
	};

	startGrabberMove = (event) => {
		this.playVideo.current.pause();
		let playbackRect = this.playBackBar.current.getBoundingClientRect();
		let seekRatio = (event.clientX - playbackRect.left) / playbackRect.width;
		const index = this.state.currently_grabbed.index;
		const type = this.state.currently_grabbed.type;
		window.addEventListener("mouseup", () => {
			window.removeEventListener("mousemove", this.startGrabberMove);
			this.addActiveSegments();
		});
		let time = this.props.timings;
		let seek = this.playVideo.current.duration * seekRatio;
		if (
			type === "start" &&
			seek > (index !== 0 ? time[index - 1].end + this.state.difference + 0.2 : 0) &&
			seek < time[index].end - this.state.difference
		) {
			this.progressBar.current.style.left = `${seekRatio * 100}%`;
			this.playVideo.current.currentTime = seek;
			time[index]["start"] = seek;
			this.setState({ playing: false });
			this.props.updateState({ timings: time });
		} else if (
			type === "end" &&
			seek > time[index].start + this.state.difference &&
			seek <
				(index !== this.props.timings.length - 1
					? time[index + 1].start - this.state.difference - 0.2
					: this.playVideo.current.duration)
		) {
			this.progressBar.current.style.left = `${
				(time[index].start / this.playVideo.current.duration) * 100
			}%`;
			this.playVideo.current.currentTime = time[index].start;
			time[index]["end"] = seek;
			this.setState({ playing: false });
			this.props.updateState({ timings: time });
		}
		this.progressBar.current.style.width = "0%";
	};

	renderGrabbers = () => {
		if (!this.playVideo.current) return [];
		return this.props.timings.map((x, index) => (
			<div key={"grabber_" + index}>
				<div
					className="grabber start"
					style={{ left: `${(x.start / this.playVideo.current.duration) * 100}%` }}
					onMouseDown={(event) => {
						if (this.state.deletingGrabber) {
							this.deleteGrabber(index);
						} else {
							this.setState(
								{ currently_grabbed: { index: index, type: "start" } },
								() => {
									window.addEventListener("mousemove", this.startGrabberMove);
								}
							);
						}
					}}
				>
					<svg
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						x="0"
						y="0"
						width="10"
						height="14"
						viewBox="0 0 10 14"
						xmlSpace="preserve"
					>
						<path
							className="st0"
							d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
						/>
					</svg>
				</div>
				<div
					className="grabber end"
					style={{ left: `${(x.end / this.playVideo.current.duration) * 100}%` }}
					onMouseDown={(event) => {
						if (this.state.deletingGrabber) {
							this.deleteGrabber(index);
						} else {
							this.setState({ currently_grabbed: { index: index, type: "end" } }, () => {
								window.addEventListener("mousemove", this.startGrabberMove);
							});
						}
					}}
				>
					<svg
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						x="0"
						y="0"
						width="10"
						height="14"
						viewBox="0 0 10 14"
						xmlSpace="preserve"
					>
						<path
							className="st0"
							d="M1 14L1 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C2 13.6 1.6 14 1 14zM5 14L5 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C6 13.6 5.6 14 5 14zM9 14L9 14c-0.6 0-1-0.4-1-1V1c0-0.6 0.4-1 1-1h0c0.6 0 1 0.4 1 1v12C10 13.6 9.6 14 9 14z"
						/>
					</svg>
				</div>
			</div>
		));
	};

	addGrabber = () => {
		let time = this.props.timings;
		let end = time[time.length - 1].end + this.state.difference;
		this.setState({ deletingGrabber: false, current_warning: null });
		if (end >= this.playVideo.current.duration) {
			return;
		}
		time.push({ start: end + 0.2, end: this.playVideo.current.duration });
		this.props.updateState({ timings: time }, () => {
			this.addActiveSegments();
		});
	};

	preDeleteGrabber = () => {
		if (this.state.deletingGrabber) {
			this.setState({ deletingGrabber: false, current_warning: null });
		} else {
			this.setState({ deletingGrabber: true, current_warning: "delete_grabber" });
		}
	};

	deleteGrabber = (index) => {
		let time = this.props.timings;
		this.setState({
			deletingGrabber: false,
			current_warning: null,
			currently_grabbed: { index: 0, type: "start" },
		});
		this.props.updateState({
			deletingGrabber: false,
			current_warning: null,
			currently_grabbed: { index: 0, type: "start" },
		});
		if (time.length === 1) {
			return;
		}
		time.splice(index, 1);
		this.progressBar.current.style.left = `${
			(time[0].start / this.playVideo.current.duration) * 100
		}%`;
		this.playVideo.current.currentTime = time[0].start;
		this.progressBar.current.style.width = "0%";
		this.addActiveSegments();
	};

	addActiveSegments = () => {
		let colors = "";
		let counter = 0;
		colors += `, rgb(240, 240, 240) 0%, rgb(240, 240, 240) ${
			(this.props.timings[0].start / this.playVideo.current.duration) * 100
		}%`;
		for (let times of this.props.timings) {
			if (counter > 0) {
				colors += `, rgb(240, 240, 240) ${
					(this.props.timings[counter - 1].end / this.playVideo.current.duration) * 100
				}%, rgb(240, 240, 240) ${(times.start / this.playVideo.current.duration) * 100}%`;
			}
			colors += `, #ccc ${(times.start / this.playVideo.current.duration) * 100}%, #ccc ${
				(times.end / this.playVideo.current.duration) * 100
			}%`;
			counter += 1;
		}
		colors += `, rgb(240, 240, 240) ${
			(this.props.timings[counter - 1].end / this.playVideo.current.duration) * 100
		}%, rgb(240, 240, 240) 100%`;
		this.playBackBar.current.style.background = `linear-gradient(to right${colors})`;
	};

	sendToRender = async (timings, mediaUrl) => {
		const formData = new FormData();
		//переводим url в blob
		const blobMediaFile = await fetch(mediaUrl).then((r) => r.blob());
		formData.append("media", blobMediaFile);
		formData.append("cuts", JSON.stringify(timings));
		console.log(timings);
		this.setState({
			isCutLoading: true,
		});
		//запрос на сервер
		try {
			const newMedia = await api.CutMedia(formData);
			console.log("Результат рендера:", newMedia);
			this.setState({
				isCutLoading: false,
			});
			if (newMedia?.data) {
				this.props.updateState({ videoUrl: newMedia.data });
			}
		} catch (e) {
			this.setState({
				isCutLoading: false,
			});
			console.log(e);
		}
	};

	saveVideo = () => {
		let metadata = {
			trim_times: this.props.timings,
		};
		this.props.saveVideo(metadata);
	};

	render = () => {
		return (
			<>
				{this.state.isGenerateOpen && (
					<GenerateWindow
						transferUrlToEditor={(videoUrl) => {
							this.props.updateState({ videoUrl: videoUrl });
						}}
						videoUrl={this.props.videoUrl}
						setIsOpen={this.setIsOpen}
					/>
				)}
				<div className="container">
					<div className="wrapper">
						<div className="upper-controls">
							<GenerateBtn setIsOpen={this.setIsOpen} />
							{this.state.isCutLoading && (
								<img
									className={"cutLoadingImg"}
									alt="Идёт генерация"
									src="./generateVideoWindow/cutLoading.svg"
								/>
							)}
							<button
								title="Отправить видео на рендер"
								className="trim-control margined"
								onClick={() => {
									this.sendToRender(this.props.timings, this.props.videoUrl);
								}}
							>
								Рендер <FontAwesomeIcon icon={faArrowsSpin} />
							</button>
							<a
								href={this.props.videoUrl}
								title="Save changes"
								className="trim-control"
								download
							>
								Сохранить
							</a>
						</div>
						<video
							style={{ display: this.props.fileType === "audio/mpeg" ? "none" : "block" }}
							className="video"
							autoload="metadata"
							muted={this.state.isMuted}
							ref={this.playVideo}
							onClick={this.play_pause.bind(this)}
						>
							<source src={this.props.videoUrl} type="video/mp4" />
						</video>
						{this.props.fileType === "audio/mpeg" && (
							<img className="audioImg" src="./videoEditor/audioImg.jpg" alt="audio" />
						)}
						<div className="playback">
							{this.renderGrabbers()}
							<div
								className="seekable"
								ref={this.playBackBar}
								onClick={this.updateProgress}
							></div>
							<div className="progress" ref={this.progressBar}></div>
						</div>

						<div className="controls">
							<div className="player-controls">
								<button
									className="settings-control"
									title="Перезапустить видео"
									onClick={this.reset}
								>
									<FontAwesomeIcon icon={faSync} />
								</button>
								<button
									className="settings-control"
									title="Убрать/Включить звук"
									onClick={() => this.setState({ isMuted: !this.state.isMuted })}
								>
									{this.state.isMuted ? (
										<FontAwesomeIcon icon={faVolumeMute} />
									) : (
										<FontAwesomeIcon icon={faVolumeUp} />
									)}
								</button>
								<button
									className="settings-control"
									title="Сделать снимок кадра"
									onClick={this.captureSnapshot}
								>
									<FontAwesomeIcon icon={faCamera} />
								</button>
								<label
									className="settings-control"
									htmlFor="upload"
									title="Загрузить видео"
								>
									<FontAwesomeIcon icon={faPlus} />
								</label>
								<input
									className="hidden"
									type="file"
									accept="video/*, audio/*"
									id="upload"
									onChange={(e) => {
										this.props.upload_file(e.target.files[0]);
									}}
								/>
							</div>
							<div className="player-controls">
								<button
									className="seek-start"
									title="Прошлая точка"
									onClick={this.skipPrevious}
								>
									<FontAwesomeIcon icon={faStepBackward} />
								</button>
								<button
									className="play-control"
									title="Воспроизвести видео/ Пауза"
									onClick={this.play_pause.bind(this)}
								>
									{this.state.playing ? (
										<FontAwesomeIcon icon={faPause} />
									) : (
										<FontAwesomeIcon icon={faPlay} />
									)}
								</button>
								<button
									className="seek-end"
									title="Следующая точка"
									onClick={this.skipNext}
								>
									<FontAwesomeIcon icon={faStepForward} />
								</button>
							</div>
							<div>
								<button
									title="Обрезать видео"
									className="trim-control margined"
									onClick={this.addGrabber}
								>
									Добавить точку <FontAwesomeIcon icon={faGripLinesVertical} />
								</button>
								<button
									title="Удалить точку"
									className="trim-control margined"
									onClick={this.preDeleteGrabber}
								>
									Удалить точку <FontAwesomeIcon icon={faGripLinesVertical} />
								</button>
							</div>
						</div>
						{this.state.current_warning != null ? (
							<div className={"warning"}>{this.warnings[this.state.current_warning]}</div>
						) : (
							""
						)}
						{this.state.imageUrl !== "" ? (
							<div className={"marginVertical"}>
								<img src={this.state.imageUrl} className={"thumbnail"} />
								<div className="controls">
									<div className="player-controls">
										<button
											className="settings-control"
											title="Скачать кадр"
											onClick={this.downloadSnapshot}
										>
											<FontAwesomeIcon icon={faDownload} />
										</button>
										<button
											className="settings-control"
											title="Удалить кадр"
											onClick={() => {
												this.setState({ imageUrl: "" });
											}}
										>
											<FontAwesomeIcon icon={faEraser} />
										</button>
									</div>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</>
		);
	};
}

export default Editor;
