import cl from "./generateWindow.module.css";
import { Header } from "../header/header";
import { Media } from "../media/media";
import { useState, createContext } from "react";
import { DescriptionInput } from "../descriptionInput/descriptionInput";
import api from "../../../api/videoGeneration";

//контекст для передачи типа генерации: аудио/видео
export const generateTypeContext = createContext(null);

//videoUrl здесь для тестов. Содержит url видео из редактора. Сейчас в коде не используется
export const GenerateWindow = ({ transferUrlToEditor, videoUrl, setIsOpen }) => {
	const [isAudio, setIsAudio] = useState(false);
	const [isLongVideo, setIsLongVideo] = useState(false);
	const [prompt, setPrompt] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [mediaUrl, setMediaUrl] = useState("");

	const generate = (prompt, isAudio, isLongVideo) => {
		if (!prompt) return;

		const generationType = isAudio ? "audio" : "video";
		const generationData = new FormData();
		generationData.append("type", generationType);
		generationData.append("prompt", prompt);

		if (!isAudio) generationData.append("isLongVideo", isLongVideo);
		setIsLoading(true);
		api
			.GetMedia(generationData)
			.then((res) => {
				console.log(res);
				// ВНИМАТЕЛЬНО! ВОЗМОЖНО В RES БУДЕТ ДРУГОЕ ПОЛЕ, НЕ DATA
				if (res?.data) {
					//let blob = fetch(res.data).then(r => r.blob());

					setMediaUrl(res.data);
					setIsLoading(false);
				}
			})
			.catch((e) => {
				setIsLoading(false);
				console.log(e);
			});
	};
	return (
		<dialog onClick={setIsOpen} className={cl.generateLayer}>
			<div className={cl.generateWindow} onClick={(e) => e.stopPropagation()}>
				<generateTypeContext.Provider value={isAudio}>
					<Header setIsAudio={setIsAudio} setIsOpen={setIsOpen} />
					<div className={cl.content}>
						<Media transferUrlToEditor={transferUrlToEditor} mediaUrl={mediaUrl} />
						<DescriptionInput
							setPrompt={setPrompt}
							setIsLongVideo={setIsLongVideo}
							isLongVideo={isLongVideo}
						/>
					</div>
					<div className={cl.submitBtns}>
						<button className={`${cl.btn} ${cl.cancelBtn}`}>
							<p className={cl.cancelBtnTxt}>Отмена</p>
						</button>
						<button
							onClick={() => generate(prompt, isAudio, isLongVideo)}
							className={`${cl.btn} ${cl.generateBtn}`}
						>
							<p className={cl.txt}>Генерация</p>
							{isLoading && (
								<img
									className={cl.loading}
									alt="Идёт генерация"
									src="./generateVideoWindow/loading.svg"
								/>
							)}
						</button>
					</div>
				</generateTypeContext.Provider>
			</div>
		</dialog>
	);
};
