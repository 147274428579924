import modalStyles from './styles/payModal.module.css'

export const BasePrice = () => {
	return (
		<div className={modalStyles.basePrice}>
			<span className={modalStyles.basePriceTxt}>
				49 900 ₽
			</span>
			<div className={modalStyles.persons}>
				<span className={modalStyles.basePriceTxt}>
					5
				</span>
				<svg xmlns='../../../../../public/Pay/person.svg' width="33" height="35" viewBox="0 0 33 35" fill="none">
					<g clipPath="url(#clip0_41_18)">
						<path d="M16.9136 16.2094C18.4393 16.2094 19.9308 15.7532 21.1994 14.8986C22.468 14.044 23.4568 12.8293 24.0406 11.4081C24.6245 9.98685 24.7773 8.423 24.4796 6.91426C24.182 5.40552 23.4473 4.01965 22.3684 2.93191C21.2895 1.84417 19.915 1.10341 18.4186 0.803298C16.9221 0.503191 15.371 0.657217 13.9614 1.2459C12.5518 1.83458 11.347 2.83148 10.4994 4.11053C9.65172 5.38957 9.19929 6.89333 9.19929 8.43163C9.19929 10.4944 10.012 12.4727 11.4588 13.9313C12.9055 15.39 14.8676 16.2094 16.9136 16.2094ZM28.485 35.6538C28.9965 35.6538 29.487 35.449 29.8487 35.0843C30.2104 34.7197 30.4136 34.2251 30.4136 33.7094C30.4136 30.0995 28.9913 26.6375 26.4595 24.0849C23.9278 21.5323 20.494 20.0983 16.9136 20.0983C13.3332 20.0983 9.89937 21.5323 7.36763 24.0849C4.83589 26.6375 3.41357 30.0995 3.41357 33.7094C3.41357 34.2251 3.61676 34.7197 3.97844 35.0843C4.34012 35.449 4.83066 35.6538 5.34215 35.6538H28.485Z" fill="#1975D2" />
					</g>
					<defs>
						<clipPath id="clip0_41_18">
							<rect width="33" height="35" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>

		</div>
	)
}