import React from "react";
import { FileDrop } from "react-file-drop";
import "./css/editor.css";
import Editor from "./Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faMoon } from "@fortawesome/free-solid-svg-icons";
import { NeuralChat } from "../../neuralBotChatWindow";
import { GenerateBtn } from "../../generateVideoWindow/generateBtn/generateBtn";
import { GenerateWindow } from "../../generateVideoWindow/generateWindow/generateWindow";
import { MainLogo } from "../../../shared/UI_kit/logo";
class VideoEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isUpload: true,
			videoUrl: "",
			isDarkMode: false,
			timings: [],
			fileType: "",
			isGenerateOpen: false,
		};
	}

	componentDidMount = () => {
		this.toggleThemes();
		document.addEventListener("drop", function (e) {
			e.preventDefault();
			e.stopPropagation();
		});
	};

	setIsOpen = () => {
		this.setState({ isGenerateOpen: !this.state.isGenerateOpen });
	};
	render_uploader = () => {
		return (
			<div style={{ padding: "20px 0 0 20px" }}>
				<MainLogo isDark={false} />
				{this.state.isGenerateOpen && (
					<GenerateWindow videoUrl={this.state.videoUrl} setIsOpen={this.setIsOpen} />
				)}
				<div className={"wrapper"}>
					<GenerateBtn setIsOpen={this.setIsOpen} />
					<input
						onChange={(e) => this.upload_file(e.target.files[0])}
						type="file"
						className="hidden"
						accept="video/*, audio/*"
						id="up_file"
					/>
					<div style={{ padding: "0px 50px " }}>
						<FileDrop
							onDrop={(e) => this.upload_file(e[0])}
							onTargetClick={() => document.getElementById("up_file").click()}
						>
							<p style={{ margin: "0 50px 0 50px" }}>
								Click or drop your video here to edit!
							</p>
						</FileDrop>
					</div>
				</div>
			</div>
		);
	};

	saveVideo = (metadata) => {
		console.log(metadata);
		// alert("Please check your console to see all the metadata. This can be used for video post-processing.")
	};

	render_editor = () => {
		return (
			// Props:
			// videoUrl --> URL of uploaded video
			// saveVideo(<metadata of edited video>) --> gives the cut times and if video is muted or not
			<div style={{ padding: "20px 0 0 20px" }}>
				<MainLogo isDark={false} />
				<Editor
					videoUrl={this.state.videoUrl}
					saveVideo={this.saveVideo}
					timings={this.state.timings}
					updateState={(st, cb) => this.setState(st, cb)}
					fileType={this.state.fileType}
					upload_file={this.upload_file}
				/>
			</div>
		);
	};

	toggleThemes = () => {
		if (this.state.isDarkMode) {
			document.body.style.backgroundColor = "#1f242a";
			document.body.style.color = "#fff";
		} else {
			document.body.style.backgroundColor = "#fff";
			document.body.style.color = "#1f242a";
		}
		this.setState({ isDarkMode: !this.state.isDarkMode });
	};

	upload_file = (file) => {
		const audioReg = /audio\/*/;
		const videoReg = /video\/*/;
		// const file = fileInput[0];
		if (!file.type.match(audioReg) && !file.type.match(videoReg)) return;
		let fileUrl = window.URL.createObjectURL(file);
		this.setState({
			isUpload: false,
			videoUrl: fileUrl,
			fileType: file.type,
		});
	};

	render = () => {
		return (
			<>
				<div>
					{this.state.isUpload ? this.render_uploader() : this.render_editor()}
					<button className={"theme_toggler"} onClick={this.toggleThemes}>
						{this.state.isDarkMode ? (
							<i className="toggle" aria-hidden="true">
								<FontAwesomeIcon icon={faLightbulb} />
							</i>
						) : (
							<i className="toggle">
								<FontAwesomeIcon icon={faMoon} />
							</i>
						)}
					</button>
					<NeuralChat uploadFileToEditor={this.upload_file} />
				</div>
			</>
		);
	};
}

export default VideoEditor;
