import cl from "./messageArea.module.css";
import { Message } from "../message/message";

export const MessageArea = ({ messages }) => {
	return (
		<div className={cl.messageArea}>
			{messages.map((message, id) => (
				<Message
					file={message.file}
					key={id}
					text={message.text}
					isUserMessage={message.isUserMessage}
				/>
			))}
		</div>
	);
};
