import cl from "./header.module.css";
import api from "../../../api/chatBot";
import { useState } from "react";

export const Header = ({ setIsOpen, setMessages }) => {
	const [isLoading, setIsLoading] = useState(false);
	const reloadContext = () => {
		setIsLoading(true);
		api
			.ReloadDialog()
			.then(() => {
				setIsLoading(false);
				setMessages([]);
			})
			.catch((err) => {
				setIsLoading(false);
				console.log(err);
			});
	};
	return (
		<div className={cl.header}>
			<p className={cl.title}>AI StableDraw</p>

			<div className={cl.btns}>
				{/* <button className={cl.fullScreenBtn}>
					<img
						className={cl.fullScreenIcon}
						src="./chatBotWindow/full-screen.svg"
						alt="Развернуть на полный экран"
					/>
				</button> */}
				{isLoading && (
					<img
						className={cl.loading}
						alt="Идёт обновление контекста"
						src="./generateVideoWindow/loading.svg"
					/>
				)}
				<button
					title="Обновить контекст диалога"
					onClick={() => {
						reloadContext();
					}}
					className={cl.CloseWindowBtn}
				>
					<img
						className={cl.closeIcon}
						src="./chatBotWindow/reload.svg"
						alt="Закрыть окно"
					/>
				</button>
				<button
					title="Закрыть окно"
					onClick={() => setIsOpen(false)}
					className={cl.CloseWindowBtn}
				>
					<img
						className={cl.closeIcon}
						src="./chatBotWindow/closeIcon.svg"
						alt="Закрыть окно"
					/>
				</button>
			</div>
		</div>
	);
};
