import cl from "./openBtn.module.css";

export const OpenBtn = ({ setIsOpen }) => {
	return (
		<button
			onClick={() => setIsOpen((prev) => !prev)}
			title="Открыть чат"
			className={cl.openBtn}
		>
			<img
				className={cl.openBtn_robotIcon}
				src="./chatBotWindow/robot.svg"
				alt="Иконка робота"
			/>
		</button>
	);
};
