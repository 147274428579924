import { Modal } from "@mui/material";
import React, { useState } from "react";
import testMob from "../../../store/neuralWindow.jsx";
import LeftPanel from "../LeftPanel/LeftPanel";
import RightPanel from "../RightPanel/RightPanel";
import cl from "./GenerateBtn.module.css";

const GenerateBtn = () => {
	const [modal, setModal] = useState(false);
	const [openParam, setOpenParam] = useState(false);

	let rootClasses = [cl.modal];
	if (modal) rootClasses.push(cl.activeModal);

	testMob.getNeurals();

	const closeBtn = () => {
		setModal(false);
		setOpenParam(false);
		testMob.setActiveNeural("");
		testMob.setCurrentModel("");
	};

	return (
		<div className={cl.generateBtn}>
			<button className={cl.button} onClick={() => setModal(!modal)}>
				<p>Генерация</p>
			</button>
			<Modal open={modal}>
				<div className={rootClasses.join(" ")}>
					<header className={cl.header}>
						<button className={cl.close} onClick={() => closeBtn()}>
							<img className={cl.closeBtn} src="/neuralWindow/Close.svg" alt="" />
						</button>
					</header>
					<div className={cl.content}>
						<LeftPanel openParam={setOpenParam} />
						<RightPanel
							showParam={openParam}
							closeWindow={closeBtn}
							closeParam={setOpenParam}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default GenerateBtn;
