import cl from "./descriptionInput.module.css";
import { useContext } from "react";
import { generateTypeContext } from "../generateWindow/generateWindow";

export const DescriptionInput = ({ setIsLongVideo, isLongVideo, setPrompt }) => {
	const isAudio = useContext(generateTypeContext);

	const handleToggle = () => {
		setIsLongVideo((prev) => !prev);
	};

	const handleInput = (e) => {
		setPrompt(e.target.value);
	};
	return (
		<div className={cl.descriptionInput}>
			<label className={cl.label_descriptionInput} htmlFor="description">
				<p className={cl.txt_descriptionInput}>Описание: </p>
			</label>

			<textarea
				id="description"
				name="description"
				placeholder="Описание"
				className={cl.input_descriptionInput}
				onChange={handleInput}
			/>
			{!isAudio && (
				<>
					<label className={cl.label_descriptionInput} htmlFor="longVideoToggle">
						<p className={`${cl.txt_descriptionInput} ${cl.txtSmall}`}>Длинное видео:</p>
					</label>
					<div
						onClick={handleToggle}
						className={`${cl.videoToggle_descriptionInput} ${
							isLongVideo ? cl.checkedColor : ""
						}`}
					>
						<input
							onChange={handleToggle}
							id="longVideoToggle"
							className={cl.inputHidden_descriptionInput}
							type="checkbox"
							checked={isLongVideo}
						/>
						<div
							className={`${cl.toggleSlider_descriptionInput} ${
								isLongVideo ? cl.checked : ""
							}`}
						></div>
					</div>
				</>
			)}
		</div>
	);
};
