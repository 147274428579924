import axios from "axios";
import AuthorizeService from "../components/api-authorization/AuthorizeService";
import ApiToken from "./ApiToken";

export default class ChatBot {
	/**
	 * Получение ответа от бота
	 * @async
	 * @static
	 * @param {FormData} formData - Объект FormData для отправки боту.
	 * FormData содержит в себе поля text и file в формате blob или null.
	 * @returns {Promise<Object|number>} Возвращает promise с объектом с полями text:string и file:blob | null
	 * или кодом 401
	 */
	static async GetAnswer(formData) {
		if (!(await AuthorizeService.isAuthenticated()))
			return axios.HttpStatusCode.Unauthorized;

		return await axios.post(
			"api/neural/textToText",
			formData,
			await ApiToken.GetConfigToken()
		);
	}

	//метод для обновления контекста диалога
	static async ReloadDialog() {
		if (!(await AuthorizeService.isAuthenticated()))
			return axios.HttpStatusCode.Unauthorized;

		return await axios.get("api/neural/resetTextToText", await ApiToken.GetConfigToken());
	}
}
