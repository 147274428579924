import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import testMob from "../../../../store/neuralWindow.jsx";
import cl from "./InputRange.module.css";

const InputRange = ({
	getValue,
	description,
	defaultV,
	max,
	min,
	step,
	name,
	keyValue,
	isValidParam,
}) => {
	const [value, setValue] = useState(defaultV);

	useEffect(() => {}, [testMob.childParams, testMob.childValues]);

	useEffect(() => {
		setValue(defaultV);
	}, [testMob.currentModel, testMob.activeNeuralName]);
	const call = (res) => {
		setValue(res);
	};

	//выделяет значение в ячейке, когда пользователь кликает по ней.
	//сделано для упрощения ввода
	const handleSelect = (e) => {
		e.target.select();
	};

	//убирает появляющийся в начале 0 при введении однозначного числа (нужно понять, где этот 0 хранится)
	const removeZero = (value) => {
		return Number(value.toString().replace(/^0+/, ""));
	};

	return (
		<>
			{isValidParam() && (
				<div className={cl.cont}>
					<div className={cl.main__cont}>
						<span className={cl.text}>{name}</span>
						<div className={cl.inputBlock}>
							<input
								className={cl.range}
								type="range"
								min={min}
								max={max}
								value={value}
								onChange={(e) => call(+e.target.value)}
								onMouseUp={() => getValue(value, keyValue)}
								step={step}
							/>
							<input
								className={cl.number}
								type="number"
								min={min}
								max={max}
								value={value}
								onChange={(e) => call(+e.target.value)}
								onBlur={() => getValue(value, keyValue)}
								onClick={handleSelect}
								step={step}
							/>
						</div>
					</div>
					<div className={cl.question}>
						<Tooltip title={description}>
							<img className={cl.paramImg} src="/neuralWindow/Question.svg" alt="" />
						</Tooltip>
					</div>
				</div>
			)}
		</>
	);
};

export default InputRange;
